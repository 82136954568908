<template>
  <div class="modal-login">
    <h2 class="has-text-primary has-text-centered is-uppercase is-size-3 has-text-weight-bold" v-if="currentStep < 6">Créer un compte</h2>
    <h2 class="has-text-primary has-text-centered is-uppercase is-size-3 has-text-weight-bold" v-if="currentStep == 6">Vous y êtes presque !</h2>
    <h2 class="has-text-primary has-text-centered is-uppercase is-size-3 has-text-weight-bold" v-if="currentStep == 7">Félicitations</h2>

    <form class="field" @submit.prevent="switchStepAccount(true)">
      <div v-if="currentStep == 1">
        <p class="has-margin-top-1 has-margin-bottom-1 has-text-centered">déjà membre ? <a  v-on:click="showLogin">Connectez-vous</a></p>
        <div class="has-text-centered">
          <FacebookLogin @close-modal="closeModal" @signup-fb-init="signupInit" />
          <GoogleLogin @close-modal="closeModal" @signup-google-init="signupInit" />
        </div>


        <p class="has-text-centered has-margin-top-1 has-margin-bottom-1">ou</p>

        <div class="field jsmb-email-account">
          <div class="control has-icons-right">
              <input class="input" :class="{'is-danger': !notError.email}" v-model="email" type="email" placeholder="Votre email" value="Votre email" required>
              <span class="icon is-small has-text-primary is-right">
                <i class="fas fa-envelope"></i>
              </span>
          </div>
          <p class="help is-danger" :class="{'is-hidden': notError.email}">{{errorMsg.email}}</p>
        </div>
        <div class="field">
          <label class="checkbox">
            <input type="checkbox" v-model="cgu" required> J’ai lu et j’accepte la <router-link :to="{name: 'DatasPolitics'}" tag="a" push>politique de gestion des donnees</router-link> de JeStockeMonBateau
          </label>
        </div>
        <div class="field firststeplast">
          <label class="checkbox">
            <input  type="checkbox" v-model="newsletter"> Je souhaite recevoir par e-mail les actualités sur JeStockeMonBateau et ses partenaires.
          </label>
        </div>
      </div>

      <div v-if="currentStep == 2">
        <div class="buttons account-type">
          <strong class="has-text-primary has-text-centered has-text-weight-bold is-uppercase has-margin-bottom-1 strong-info">Merci de sélectionner le type de compte souhaité :</strong>
          <button v-for="(userType, index) in userTypes" :key="'user-type-'+index" type="button" class="button" :class="[!notError.userType ? 'is-danger is-outlined' : '', user_type_id == userType.id ? 'is-primary' : '']" @click="[notError.userType=true, user_type_id=userType.id]" v-model="user_type_id">{{userType.name}}</button>
        </div>
        <div class="field jsmb-email-account">
          <div class="control has-icons-right">
            <input class="input" v-model="email" type="email" placeholder="Votre email" value="Votre email" disabled>
            <span class="icon has-text-primary is-small is-right">
              <i class="fas fa-envelope"></i>
            </span>
          </div>
        </div>
        <b-field>
          <b-input name="password" type="password" v-model="password" placeholder="Mot de passe" minlength=8 maxlength=20 password-reveal required></b-input>
        </b-field>
        <div>
          <h3 class="has-text-primary has-text-centered has-text-weight-bold is-uppercase has-margin-bottom-1">
            vous êtes
          </h3>
          <div class="buttons account-type" v-if="!skipBoat">
            <button v-for="(accountType, index) in accountTypes" :key="'account-type-'+index" type="button" class="button" :class="[!notError.accountType ? 'is-danger is-outlined' : '', account_type_id == accountType.id ? 'is-primary' : '']" @click="[notError.accountType=true, account_type_id=accountType.id]" v-model="account_type_id">{{accountType.name}}</button>
          </div>
          <div class="buttons account-type">
            <button v-for="(userGender, index) in userGenders" :key="'user-gender-'+index" type="button" class="button" :class="[!notError.userGender ? 'is-danger is-outlined' : '', user_gender_id == userGender.id ? 'is-primary' : '']" @click="[notError.userGender=true, user_gender_id=userGender.id]" v-model="user_gender_id">{{userGender.name}}</button>
          </div>
        </div>
        <br>
        <div class="field">
            <div class="control">
              <input class="input" v-model="first_name" type="text" placeholder="Votre prénom" value="Votre prénom" required>
          </div>
        </div>
        <div class="field has-margin-bottom-1">
          <div class="control">
            <input class="input" v-model="last_name" type="text" placeholder="Votre nom" value="Votre nom" required>
          </div>
        </div>
        <br>
      </div>

      <div v-if="currentStep == 3">
        <div>
          <h3 class="has-text-primary has-text-centered has-text-weight-bold is-uppercase has-margin-bottom-1">
            Quelques mots sur vous.
          </h3>
          <p>Les utilisateurs qui remplissent leur description ont 5 fois plus de chance d'être contactés.</p>
        </div>
        <br>
        <div class="field ">
          <div class="control">
            <b-field label="Votre description">
              <b-input maxlength="900" type="textarea" v-model="bio"></b-input>
            </b-field>
          </div>
        </div>
        <br>
      </div>

      <div v-if="currentStep == 4">
        <Area :hideForModal="false" :boatDataForModal="boatData" @boat-data-modal="getBoatDataModal"/>
        <p class="help is-danger has-text-centered" :class="{'is-hidden': notError.boatType}">{{errorMsg.boatType}}</p>
        <br>
      </div>

      <div v-if="currentStep == 5">
        <h3 class="has-text-primary has-text-centered has-text-weight-bold is-uppercase has-margin-bottom-1">Ajoutez votre photo !</h3>
        <br>
        <p class="has-text-centered has-margin-bottom-1 has-margin-top-1">Complétez votre profil en ajoutant une photo !<br>Nous l’ajouterons à votre profil, et les futurs Bateaux-Stockeurs ou Bateaux-skippers pourront ainsi la voir.</p>
        <div v-if="imgCrop === null">
          <figure class="image img-is-centered is-128x128">
            <img class="image img-is-centered is-rounded" :src="imgSrc" alt="JeStockeMonBateau">
          </figure>
          <p class="help is-danger has-text-centered has-margin-bottom-1" :class="{'is-hidden': notError.imgUpload}">{{errorMsg.imgUpload}}</p>
          <div class="buttons jsmb-social is-centered">
            <a class="button is-fullwidth facebook-button has-margin-bottom-1" @click="uploadFbProfilPicture">Utiliser votre photo Facebook</a>
            <a class="button is-fullwidth google-button has-margin-bottom-1" v-if="google_id!==''" @click="uploadGoogleProfilPicture">Utiliser votre photo Google</a>
            <b-field class="file">
              <b-upload v-model="imgUpload" @input="uploadProfilPicture()">
                <a class="button is-fullwidth has-margin-top-1 has-margin-bottom-1 jsmb-upload">Télécharger une photo de profil</a>
              </b-upload>
            </b-field>
          </div>
        </div>
        <div v-else>
          <vueCropper
            ref="cropper"
            :img="imgCrop"
            :autoCrop="true"
            :autoCropWidth="128"
            :autoCropHeight="128"
            :fixedBox="true"
            mode="cover"
          ></vueCropper>
        </div>
        <br>
      </div>

      <div v-if="currentStep == 6">
        <h3 class="has-text-primary has-text-centered has-text-weight-bold is-uppercase has-margin-bottom-1">Confirmez votre numéro de téléphone !</h3>
        <br>
        <p class="has-text-centered has-margin-bottom-1 has-margin-top-1">Ce numéro permettra aux utilisateurs du site de vous contacter lors de votre prochaine location</p>
        <img class="image img-is-centered" :src="require('./../../../assets/modal-phone.png')" alt="JeStockeMonBateau">
        <input class="input" type="tel" v-model="phone" placeholder="Votre numéro de téléphone !" pattern="^[0]{1}[0-9]{9}$">
        <p class="help is-danger has-text-centered has-margin-bottom-1" :class="{'is-hidden': notError.phone}">{{errorMsg.phone}}</p>
      </div>

      <div v-if="currentStep == 7">
        <h3 class="has-text-primary has-text-centered has-text-weight-bold is-uppercase has-margin-bottom-1">Votre compte a été créé !</h3>
        <p class="has-text-centered has-margin-bottom-1 has-margin-top-1">Vous allez maintenant recevoir un email de validation afin de confirmer la création de votre compte.</p>
        <p class="has-text-centered has-margin-bottom-1 has-margin-top-1" style="color:#df5051">Pensez a vérifier vos courriers indésirables afin de valider votre compte <span v-if="!skipBoat">et déposer votre annonce</span></p>
        <p class="has-text-centered has-margin-bottom-1 has-margin-top-1">Merci pour votre confiance.</p>
        <img class="image img-is-centered" :src="require('./../../../assets/logo-modal.png')" alt="JeStockeMonBateau">
      </div>

      <div v-if="currentStep == 8">
        <h3 class="has-text-primary has-text-centered has-text-weight-bold is-uppercase has-margin-bottom-1">Votre compte a été créé !</h3>
        <p class="has-text-centered has-margin-bottom-1 has-margin-top-1">Vous pouvez maintenant naviguer et profiter de l'ensemble des fonctionnalités du site !</p>
        <img class="image img-is-centered" :src="require('./../../../assets/logo-modal.png')" alt="JeStockeMonBateau">
      </div>

      <div class="buttons account-steps">
        <button v-if="buttonBack" type='button' v-on:click="switchStepAccount(false)" class="button is-danger ">Précédent</button>
        <button v-if="buttonDone" type='button' v-on:click="closeModal" class="button is-danger ">Fermer</button>
        <button v-if="buttonNext" type="submit" class="button is-danger" :class="buttonNextLoad?'is-loading':''" :disabled="buttonNextLoad?true:false">Suivant</button>
      </div>

      <div class="has-text-centered">
        <button v-if="buttonNextCenter" type='submit' class="button is-danger ">Suivant</button>
        <button v-if="buttonDoneCenter" type='button' v-on:click="closeModal" class="button is-danger ">Fermer</button>
        <button v-if="buttonCropCenter" type='button' class="button is-primary" style="margin-right:1rem;" v-on:click="$refs.cropper.changeScale(-1)"> - </button>
        <button v-if="buttonCropCenter" type='button' class="button is-danger" v-on:click="cropProfilPicture">Valider</button>
        <button v-if="buttonCropCenter" type='button' class="button is-primary" style="margin-left:1rem;" v-on:click="$refs.cropper.changeScale(1)"> + </button>
      </div>
    </form>
  </div>
</template>

<script>
import Area from '@/components/landing/landing-sections/research-cta/res-sections/Area.vue'
import FacebookLogin from '@/components/template/ui/FacebookLogin.vue'
import GoogleLogin from '@/components/template/ui/GoogleLogin.vue'
import { SIGNUP_CHECK_MAIL, SIGNUP_REQUEST } from '@/store/modules/entrance/actions/entrance.actions'
import { GET_USER_TYPE, GET_ACCOUNT_TYPE, GET_USER_GENDER, ADD_BOAT_REQUEST } from '@/store/modules/database/actions/database.actions'
import { VueCropper }  from "vue-cropper"

export default {
  name: 'Signup',
  components: {
    Area,
    VueCropper,
    FacebookLogin,
    GoogleLogin
  },
  data(){
    return {
      userTypes: [],
      accountTypes: [],
      userGenders: [],

      notError: {
        email: true,
        userType: true,
        accountType: true,
        userGender: true,
        boatType: true,
        imgUpload: true,
        phone: true,
      },
      errorMsg: {
        email: '',
        boatType: '',
        imgUpload: '',
        phone: '',
      },

      buttonBack: false,
      buttonNext: false,
      buttonNextLoad: false,
      buttonDone: false,
      buttonNextCenter: false,
      buttonDoneCenter: false,
      buttonCropCenter: false,

      cgu: JSON.parse(localStorage.getItem('cgu')) || false,
      newsletter: JSON.parse(localStorage.getItem('newsletter')) || false,
      email: localStorage.getItem('emailAddress') || '',
      password: localStorage.getItem('password') || '',
      first_name: localStorage.getItem('first_name') || '',
      last_name: localStorage.getItem('last_name') || '',
      facebook_id: localStorage.getItem('facebook_id') || '',
      google_id: localStorage.getItem('google_id') || '',
      user_type_id: localStorage.getItem('user_type_id') || '',
      account_type_id: localStorage.getItem('account_type_id') || '',
      user_gender_id: localStorage.getItem('user_gender_id') || '',
      bio: localStorage.getItem('bio') || '',
      skipBoat: true,
      boatData: {
        boat_type_id: localStorage.getItem('boat_type_id') || 0,
        length: localStorage.getItem('boat_length') || 1,
        height: localStorage.getItem('boat_height') || 1,
        width: localStorage.getItem('boat_width') || 1,
      },
      imgSrc: localStorage.getItem('imgUpload') || require('./../../../assets/profil-modal.png'),
      imgUpload: null,
      imgCrop: localStorage.getItem('imgCrop') || null,
      phone: '',
    }
  },
  props: {
    currentStep: {
      type:Number,
    }
  },
  methods: {
    showLogin() {
      this.$emit('showLogin')
    },
    closeModal () {
      if (localStorage.getItem('modalCurrentStep') == 7) {
        localStorage.removeItem('modalCurrentStep')
      }
      this.$emit('close-modal')
    },
    getBoatDataModal (vModel, value) {
      switch (vModel) {
        case 'boat_type_id':
          this.boatData.boat_type_id = value
          localStorage.setItem('boat_type_id', value)
          break;

        case 'length':
          this.boatData.length = value
          localStorage.setItem('boat_length', value)
          break;

        case 'height':
          this.boatData.height = value
          localStorage.setItem('boat_height', value)
          break;

        case 'width':
          this.boatData.width = value
          localStorage.setItem('boat_width', value)
          break;
      }
    },
    updateShowButton (back, done, next, nextCenter, doneCenter, cropCenter) {
      this.buttonBack = back
      this.buttonDone = done
      this.buttonNext = next
      this.buttonNextCenter = nextCenter
      this.buttonDoneCenter = doneCenter
      this.buttonCropCenter = cropCenter
    },
    switchShowButton (currentStep) {
      switch (currentStep) {
        case 1:
          this.updateShowButton(false, false, false, true, false, false)
          break;

        case 2:
          this.updateShowButton(true, false, true, false, false, false)
          break;

        case 3:
          this.updateShowButton(true, false, true, false, false, false)
          break;

        case 4:
          this.updateShowButton(true, false, true, false, false, false)
          break;

        case 5:
          this.updateShowButton(true, false, true, false, false, false)
          break;

        case 'crop':
          this.updateShowButton(false, false, false, false, false, true)
          break;

        case 6:
          this.updateShowButton(true, false, true, false, false, false)
          break;

        case 7:
          this.updateShowButton(false, false, false, false, true, false)
          break;

        case 8:
          this.updateShowButton(false, false, false, false, true, false)
          break;
      }
    },
    switchStepAccount(isNext, isSkip = false) {
      switch (this.currentStep) {
        case 1:
          if (isNext) {
            const { email } = this
            this.$store.dispatch(
              SIGNUP_CHECK_MAIL, { email }
            ).then(
              resp => {
                localStorage.setItem('cgu', this.cgu)
                localStorage.setItem('newsletter', this.newsletter)
                this.$emit('change-current-step', isNext)
                this.switchShowButton(this.currentStep+1)
                this.notError.email = true
                this.errorMsg.email = ''
              }
            ).catch(
              error => {
                const { msg } = error.data.response
                localStorage.removeItem('cgu')
                localStorage.removeItem('newsletter')
                localStorage.removeItem('modalCurrentStep')
                this.switchShowButton(this.currentStep)
                this.notError.email = false
                this.errorMsg.email = msg['email'][0]

              }
            )
          }
          break;

        case 2:
          localStorage.setItem('password', this.password)
          localStorage.setItem('first_name', this.first_name)
          localStorage.setItem('last_name', this.last_name)
          if (this.password !== '' && this.user_type_id !== '' && this.account_type_id !== '' && this.user_gender_id !== '') {
            localStorage.setItem('user_type_id', this.user_type_id)
            localStorage.setItem('account_type_id', this.account_type_id)
            localStorage.setItem('user_gender_id', this.user_gender_id)
            if (isNext) {
              if (this.skipBoat) {
                this.$emit('change-current-step', isNext, true, 4)
              }else{
                this.$emit('change-current-step', isNext)
              }
              this.switchShowButton(this.currentStep+1)
            }else{
              this.$emit('change-current-step', isNext)
              this.switchShowButton(this.currentStep-1)
            }
          }else{
            if (isNext) {
              if (this.user_type_id === '') {
                this.notError.userType = false
              }
              if (this.account_type_id === '') {
                this.notError.accountType = false
              }
              if (this.user_gender_id === '') {
                this.notError.userGender = false
              }
            }else{
              this.notError.userType = true
              this.notError.accountType = true
              this.notError.userGender = true
              this.$emit('change-current-step', isNext)
              this.switchShowButton(this.currentStep-1)
            }
          }
          break;

        case 3:
          localStorage.setItem('bio', this.bio)
          if (isNext) {
            if (this.skipBoat) {
              this.$emit('change-current-step', isNext)
            }else{
              this.$emit('change-current-step', isNext, true, 5)
            }
            this.switchShowButton(this.currentStep+1)
          }else{
            this.$emit('change-current-step', isNext)
            this.switchShowButton(this.currentStep-1)
          }
          break;

        case 4:
          if (this.boatData.boat_type_id !== 0) {
            this.notError.boatType = true
            this.errorMsg.boatType = ''
            this.$emit('change-current-step', isNext)
          }else{
            if (isNext) {
              this.notError.boatType = false
              this.errorMsg.boatType = 'Veuillez indiquer quelle type bateau voulez-vous stocker.'
            }else{
              this.notError.boatType = true
              this.errorMsg.boatType = ''
              this.$emit('change-current-step', isNext, true, 2)
              this.switchShowButton(this.currentStep-1)
            }
          }
          break;

        case 5:
          if (isNext) {
            // if (localStorage.getItem('imgUpload') !== null) {
              this.notError.imgSrc = true
              this.errorMsg.imgSrc = ''
              this.$emit('change-current-step', isNext)
              this.switchShowButton(this.currentStep+1)
            // }else{
            //   this.notError.imgSrc = false
            //   this.errorMsg.imgSrc = 'Veuillez ajouter une photo de profil à votre compte.'
            // }
          }else{
            this.notError.imgSrc = true
            this.errorMsg.imgSrc = ''
            if (this.skipBoat) {
              this.$emit('change-current-step', isNext)
              }else{
              this.$emit('change-current-step', isNext, true, 3)
            }
            this.switchShowButton(this.currentStep-1)
          }
          break;

        case 6:
          console.log('Step 6');
          if (isNext) {
            if (this.phone !== '') {
              this.notError.phone = true
              this.errorMsg.phone = ''
              this.buttonNextLoad = true
              const { newsletter, email, password, first_name, last_name, facebook_id, google_id, user_type_id, account_type_id, user_gender_id, bio, boatData, imgSrc, phone } = this
              let phone33 = this.phone.replace('0','+33')
              this.closeModal()
              this.$store.dispatch(
                SIGNUP_REQUEST,
                { newsletter, email, password, first_name, last_name, facebook_id, google_id, user_type_id, account_type_id, user_gender_id, bio, imgSrc, phone33 }
              ).then(
                resp => {
                  const {success, msg, data} = resp.data.response
                  this.buttonNextLoad = false
                  if (success && this.skipBoat) {
                    // let userId = data.id;
                    let userId = data.user_id;
                    this.$store.dispatch(
                      ADD_BOAT_REQUEST,
                      { userId, boatData }
                    )
                  }
                  localStorage.removeItem('emailAddress')
                  localStorage.removeItem('cgu')
                  localStorage.removeItem('newsletter')
                  localStorage.removeItem('password')
                  localStorage.removeItem('first_name')
                  localStorage.removeItem('last_name')
                  localStorage.removeItem('facebook_id')
                  localStorage.removeItem('google_id')
                  localStorage.removeItem('google_img')
                  localStorage.removeItem('user_type_id')
                  localStorage.removeItem('account_type_id')
                  localStorage.removeItem('user_gender_id')
                  localStorage.removeItem('bio')
                  localStorage.removeItem('boat_type_id')
                  localStorage.removeItem('boat_length')
                  localStorage.removeItem('boat_height')
                  localStorage.removeItem('boat_width')
                  localStorage.removeItem('imgUpload')
                  // Suppression Vérification Mail
                  switch (data.user_type) {
                    case 'stockeur':
                      this.$router.push({ name: 'Wizard' })
                      break;

                    case 'skipper':
                      this.$router.push({ name: 'Account' })
                      // this.$router.go() //didn't work on IOS
                      // window.history.go();
                      localStorage.removeItem('modalCurrentStep')
                      break;
                  }
                  this.$emit('change-current-step', isNext)
                  this.switchShowButton(this.currentStep+1)
                }
              )
            }else{
              this.notError.phone = false
              this.errorMsg.phone = 'Veuillez renseigner votre numéro de téléphone.'
            }
          }else{
            this.notError.phone = true
            this.errorMsg.phone = ''
            this.$emit('change-current-step', isNext)
            this.switchShowButton(this.currentStep-1)
          }
          break;

        case 7:
          this.$emit('change-current-step', isNext)
          if (isNext) {
            this.switchShowButton(this.currentStep+1)
          }else{
            this.switchShowButton(this.currentStep-1)
          }
          break;
      }
    },
    signupInit () {
      this.email = localStorage.getItem('emailAddress')
      this.cgu = JSON.parse(localStorage.getItem('cgu'))
      this.newsletter = JSON.parse(localStorage.getItem('newsletter'))
      this.$emit('change-current-step', null, true, 2)
      this.switchShowButton(2)
      this.notError.email = true
      this.errorMsg.email = ''

      this.first_name = localStorage.getItem('first_name')
      this.last_name = localStorage.getItem('last_name')
      if (localStorage.getItem('facebook_id') !== null) {
        this.facebook_id = localStorage.getItem('facebook_id')
      }else if (localStorage.getItem('google_id') !== null) {
        this.google_id = localStorage.getItem('google_id')
      }
    },
    uploadFbProfilPicture () {
      var vueThis = this;

      window.fbAsyncInit = function() {
        FB.init({
          appId            : '490013759543297',
          autoLogAppEvents : true,
          xfbml            : true,
          version          : 'v3.2'
        });

        FB.login(function(response) {
          FB.api('/me', 'GET', { fields: 'picture.width(128).height(128)' }, function(respApi) {
            var img = new Image()
            img.crossOrigin = 'Anonymous'
            img.onload = function(){
              var canvas = document.createElement('CANVAS'),
              ctx = canvas.getContext('2d'), dataURL
              canvas.height = img.height
              canvas.width = img.width
              ctx.drawImage(img, 0, 0)
              dataURL = canvas.toDataURL()
              canvas = null

              vueThis.imgSrc = dataURL
              localStorage.setItem('imgUpload', dataURL)
            };
            img.src = respApi.picture.data.url;
          });
        }, {scope: 'public_profile'});
      }

      (function(d, s, id){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/fr_FR/sdk.js";
      //js.src = "https://connect.facebook.net/fr_FR/sdk/debug.js";
      fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    },
    uploadGoogleProfilPicture () {
      localStorage.setItem('imgUpload', localStorage.getItem('google_img'))
      this.imgSrc = localStorage.getItem('google_img')
    },
    uploadProfilPicture () {
      var vueThis = this;

      if(window.FileReader) {
        var file  = vueThis.imgUpload
        vueThis.imgUpload = null
        var reader = new FileReader()

        if (file && file.type.match('image.*')) {
          vueThis.notError.imgUpload = true
          vueThis.errorMsg.imgUpload = ''
          reader.readAsDataURL(file)
          reader.onloadend = function (e) {
            vueThis.imgCrop = reader.result
            localStorage.setItem('imgCrop', reader.result)
            vueThis.switchShowButton ('crop')
          }
        } else {
          vueThis.notError.imgUpload = false
          vueThis.errorMsg.imgUpload = 'Veuillez envoyer seulement des fichiers image.'
        }
      }
    },
    cropProfilPicture() {
      this.$refs.cropper.getCropData((data) => {
        this.imgSrc = data
        localStorage.setItem('imgUpload', data)
        this.imgCrop = null
        localStorage.removeItem('imgCrop')
        this.switchShowButton (this.currentStep)
      })
    }
  },
  created () {
    if (this.imgCrop === null) {
      this.switchShowButton (this.currentStep)
    }else{
      this.switchShowButton ('crop')
    }
    if (localStorage.getItem('user_type_id') == 1) {
      // Bateau Stocker
      this.skipBoat = false
    }
    // Get UserTypes
    this.$store.dispatch(
      GET_USER_TYPE
    ).then(
      resp => {
        this.userTypes = resp
      }
    )
    // Get AccountTypes
    this.$store.dispatch(
      GET_ACCOUNT_TYPE
    ).then(
      resp => {
        this.accountTypes = resp
      }
    )
    // Get UserGenders
    this.$store.dispatch(
      GET_USER_GENDER
    ).then(
      resp => {
        this.userGenders = resp
      }
    )
  },
  updated () {
    if (this.$props.currentStep === 8 && this.skipBoat) {
      this.$analytics.fbq.event('CompleteRegistration')
    }
  },
  watch: {
    user_type_id() {
      if (this.user_type_id == 1) {
        // Bateau Stocker
        this.skipBoat = false
        this.account_type_id = localStorage.getItem('account_type_id') || ''
      }else{
        // Bateau Skipper
        this.skipBoat = true
        this.account_type_id = 1
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  .vue-cropper {
    width: 300px;
    min-height: 300px;
    margin: 0 auto;
  }
  .modal {
    z-index: 999;
  }
  .modal-card>div {
    position:relative;
    /* height: 100%; */
  }
  .img-is-centered {
    margin: 2rem auto
  }
  h2 {
    margin-bottom: 0.5rem;
  }
  .facebook-button {
    background:#4568b2;
    color:#fff
  }
  .facebook-button:hover {
    background:#fff;
    color:#4568b2;
    border-color: #4568b2
  }
  .jsmb-social .button {
    padding:1.5rem 0;
  }
  .has-margin-bottom-1 {
    margin-bottom: 1rem;
  }
  .has-margin-top-1 {
    margin-top: 1rem;
  }
  .moreInfo {
    margin: 2rem auto;
    display: inline-flex
  }
  :placeholder {
    color: #000
  }
  .firststeplast,
  .field.jsmb-email-account {
    margin-bottom: 1.5rem;
  }
  input:not([type="checkbox"]){
      margin-bottom: 0.5rem;
      border:none;
  }
  input[type=checkbox] {
      border: 1px solid #df5051;
      padding: 0.5em;
  -webkit-appearance: none;
  }
  input[type=checkbox]:checked {
      background-size: 9px 9px;
      background-color: #df5051;
  }
  input[type=checkbox]:focus {
      outline-color: none;
  }
  .checkbox {
    font-size: 0.8rem
  }
  .buttons.account-steps {
    /* position: absolute;
    bottom:0;
    width: 100%; */
    justify-content: space-between;
  }
  @media screen and (max-width:768px) {
    .buttons.account-steps {
      position: relative;
      margin-top: 1.5rem;
    }
    .modal-card {
      height: auto;
    }
  }
  .buttons.account-type {
    justify-content: space-between;
  }
  .account-type .button:hover {
    background-color: #27c6bd;
    border-color: transparent;
    color: #fff;
  }
   .jsmb-upload {
    padding-right: 1rem !important;
    padding-left: 1rem !important
  }
  .field.file,
  .upload.control {
    width: 100%;
  }
</style>
<style>
  #modal-login .modal-card {
    height: max-content;
    overflow: auto;
    display:block
  }
  #modal-login input.input {
    box-shadow: none;
    color: #000;
    border:1px solid #d1d1d1
  }
  #modal-login .modal-card i.fas.fa-eye.fa-lg {
    font-size: 19px !important;
  }
  #modal-login .modal-card .input.is-danger {
    border-color: #df5051;
  }
  .modal-card {
    overflow: auto !important;
  }
  .strong-info {
    font-size: 0.9rem;
    text-align: center;
    display: flex;
    margin: 0 auto 1.5rem;
  }

</style>
