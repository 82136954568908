<template>
  <div class="modal-card">
      <Login v-if="currentStep == 0" @showForgottenPassword="currentStep=9" @showSignUp="currentStep=1" @signup-init="currentStep=2" :authErrorSubmitAd="authErrorSubmitAd"/>
      <Signup @close-modal="closeModal" v-else-if="currentStep >= 1 && currentStep < 9" :currentStep="currentStep" @showLogin="currentStep=0" @change-current-step="changeCurrentStep" />
      <Forgotten @close-modal="closeModal" v-else-if="currentStep >= 9 && currentStep < 13" :currentStep="currentStep" @change-current-step="changeCurrentStep" />
      <b-loading :is-full-page="true" :active.sync="mainLoading"></b-loading>
      <b-loading :is-full-page="true" :active.sync="facebookMainLoading"></b-loading>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Login from '@/components/entrance/entrance-login/Login.vue'
import Signup from '@/components/entrance/entrance-signup/Signup.vue'
import Forgotten from '@/components/entrance/entrance-forgotten/Forgotten.vue'
import { STATE_MODAL } from '@/store/modules/landing/actions/landing.actions'

export default {
  name: 'Entrance',
  data() {
    return {
      currentStep: JSON.parse(localStorage.getItem('modalCurrentStep')) || 1,
      loading: false,
    }
  },
  props: {
    modalSignUpDone: {type: Boolean},
    modalForgotten: {type: Boolean},
    authErrorSubmitAd: {type: Boolean},
  },
  components: {
    Login,
    Signup,
    Forgotten,
  },
  methods: {
    closeModal () {
      this.$store.dispatch(STATE_MODAL, {'show_modal': false})
    },
    changeCurrentStep (isNext, inLocalStorage = true, value = 0) {
      if (value === 0) {
        if (isNext) {
          this.currentStep += 1
        }else{
          this.currentStep -= 1
        } 
      }else{
        this.currentStep = value
      }
      if (inLocalStorage) {
        localStorage.setItem('modalCurrentStep', this.currentStep)
      }
    },
  },
  beforeMount () {
    if (this.modalSignUpDone) {
      this.currentStep = 7
      this.$emit('modal-sign-up-no-show')
      localStorage.removeItem('modalCurrentStep')
    }else if (this.modalForgotten) {
      this.currentStep = 11
      this.$emit('modal-forgotten-no-show')
    }
  },
  computed: {
    ...mapGetters(['mainLoading', 'facebookMainLoading', 'showSignup']),
  },
  mounted() {
    if (this.$store.state.modalEntrance.show_signup){
      this.currentStep = 1
      this.$store.dispatch(STATE_MODAL, {'show_modal': true, 'show_signup': false})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
.modal-card {
  height: 60vh;
  padding: 2rem;
  max-width: 30rem;
  background-color: #fff;
}
.modal .animation-content .modal-card {
  margin: 0 auto;

}
  @media screen and (max-width:768px) {
    .modal-card {
      height: auto;
    }
  }
</style>
