<template>
    <form class="field" @submit.prevent="researchStorage">
      <small>Sélectionner votre type de bateau ainsi que ses dimensions</small>
      <div class="form-cta">
        <div class="form-top has-text-text" :class="$mq | mq({desktop:'',tablet:'', mobile: 'jsmb-wrap' })">
          <div class=" form-top-item" v-for="(boatType, index) in boatTypes" :key="'boat-type-'+index">
            <figure class="image is-64x64">
                <img :src="require('./../../../../../assets/'+boatType.slug+'.svg')" :alt="boatType.name" class="">
            </figure>
            <b-checkbox v-if="hideForModal" v-model="boatTypeId" :native-value="boatType.id"></b-checkbox>
            <b-radio v-if="!hideForModal" v-model="boat_type_id" :native-value="boatType.id" @input="onChange('boat_type_id')"></b-radio>
            <p class="has-text-text is-area">{{boatType.name}}</p>
          </div>
        </div>
      </div>

      <div class="columns is-multiline search-area">

        <div class="column is-12">
          <div class="control">
            <span class="label is-size-7 is-uppercase has-text-dark has-text-weight-regular">Longueur</span>
            <b-tooltip always :label="String(length + ' m')" position="is-right">
              <input class="slider" v-model="length" step="1" min="1" max="25" type="range" @change="onChange('length')"/>
            </b-tooltip>
          </div>
        </div>

        <div class="column is-12">
         <div class="control">
           <span class="label is-size-7 is-uppercase has-text-dark has-text-weight-regular">Largeur</span>
           <b-tooltip always :label="String(width + ' m')" position="is-right">
             <input class="slider" v-model="width" step="1" min="1" max="10" type="range" @change="onChange('width')"/>
           </b-tooltip>
         </div>
        </div>

        <div class="column is-12">
          <div class="control">
            <span class="label is-size-7 is-uppercase has-text-dark has-text-weight-regular">Hauteur</span>
            <b-tooltip always :label="String(height + ' m')" position="is-right">
              <input class="slider" v-model="height" step="1" min="1" max="10" type="range" @change="onChange('height')"/>
           </b-tooltip>
         </div>
        </div>

      </div>

      <div v-if="hideForModal"  :class="displayOnTouch ? '' : 'columns'">
        <div class="has-text-right column">
          <a v-if="this.$route.name === 'Researchcity' || this.$route.name === 'Research'" @click="getLinks" class="refresh-search-button">Appliquer</a>
          <button v-else @click="getLinks" class="is-primary button">Rechercher</button>
        </div>
      </div>
   </form>
</template>

<script>
import { GET_BOAT_TYPE } from '@/store/modules/database/actions/database.actions'

export default {
  name: 'Area',
  props: {
    hideForModal: {
      type:Boolean,
      default:true,
    },
    boatDataForModal: {
      type:Object,
      default: () => {
        return {
          boat_type_id: 0,
          length: 1,
          width: 1,
          height: 1,
        }
      },
    },
    boatData: {
      type:Object,
      default: () => {
        return {
          boatTypeId: [],
          length: 1,
          width: 1,
          height: 1,
        }
      },
    }
  },
  data () {
    return {
      boatTypes: [],
      boatTypeId: [],
      boat_type_id: 0,

      length: 1,
      width: 1,
      height: 1,
    }
  },
  created () {
    if (!this.hideForModal) {
      // Initialisation Props boatDataForModal
      this.boat_type_id = this.boatDataForModal.boat_type_id
      this.length = this.boatDataForModal.length
      this.width = this.boatDataForModal.width
      this.height = this.boatDataForModal.height
    }else{
      // Initialisation Props boatData
      this.boatTypeId = this.boatData.boatTypeId
      this.length = this.boatData.length
      this.width = this.boatData.width
      this.height = this.boatData.height
    }
    // Get BoatTypes
    this.$store.dispatch(
      GET_BOAT_TYPE
    ).then(
      resp => {
        this.boatTypes = resp
      }
    )
  },
  methods: {
    onChange(vModel) {
      if (!this.hideForModal) {
        switch (vModel) {
          case 'boatTypeId':
            this.$emit('boat-data-modal', 'boatTypeId', this.boatTypeId)
            break;

          case 'boat_type_id':
            this.$emit('boat-data-modal', 'boat_type_id', this.boat_type_id)
            break;
        
          case 'length':
            this.$emit('boat-data-modal', 'length', this.length)
            break;

          case 'width':
            this.$emit('boat-data-modal', 'width', this.width)
            break;

          case 'height':
            this.$emit('boat-data-modal', 'height', this.height)
            break;
        }
      }
    },
    getLinks() {
      let query = {
        ...this.$route.query,
        ...{
          boatTypeId: JSON.stringify(this.boatTypeId),
          length: this.length,
          width: this.width,
          height: this.height
        }
      }
      if (this.$route.name === 'Researchcity' || this.$route.name === 'Research') {
        this.$router.push({ name: this.$route.name, params: this.$route.params, query: query })
        this.$emit('update-area', query)
      }else{
        this.$router.push({ name: 'Research', query: query })
      }
      this.$parent.$parent.isActive = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  /* TOP OF FOM */
  .form-top-item .image img {
    width:90%
  }
  .is-area {
    font-size:11px;
  }
  .form-cta {
    margin-top: 1rem;
  }
  .form-top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 1rem;
    margin: 0 0rem;
  }
  .form-top-item{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-top-item p{
    padding-top: .8rem;
  }  
  .b-tooltip {
    display: flex;
  }
  .slider {
    -webkit-appearance: none;
    width: 85%;
    height: 10px;
    border-radius: 5px;
    background: #0ec0b6;
    outline: none;
    opacity: 1;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    padding: 0;
  }
  .slider::-webkit-slider-thumb {
    min-height: 20px;
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%; 
    background: #0ec0b6;
    border: 2px solid white;
    cursor: pointer;
  }
  .slider::-moz-range-thumb {
    min-height: 20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #0ec0b6;
    cursor: pointer;
  }
  .slider::-ms-thumb {
    min-height:10px;
    width: 10px;
    height: 10px;
    border-radius: 50%; 
    background-color: #0ec0b6;
    border:2px solid #fff;
    cursor: pointer;
  }
  .b-tooltip.is-right:after {
    left: calc(85% + 5px + 2px);
    top: 3px;
  }
  .b-tooltip.is-right.is-primary:before {
    left: calc(85% + 2px);
    top: 3px;
  }
  @media screen and (max-width: 768px) {
    .slider {
      width: 85%;
    }
    .b-tooltip.is-right:after {
      left: calc(85% + 5px + 2px);
    }
    .b-tooltip.is-right.is-primary:before {
      left: calc(85% + 2px);
    }
    .slider::-webkit-slider-thumb,
    .slider::-moz-range-thumb {
      width: 20px;
      height: 20px;
    }
    .research-cta .form-top {
      flex-wrap: wrap;
      justify-content: space-around
    }
  }
  .search-area .control {
    margin-bottom: 1.5rem;
  }
  .refresh-search-button {
    font-size:0.7rem;
    color: #df5051;
    padding-right: 0.8rem;
  }
  .area-research .search-area {
    width: 29rem;
  }
  @media screen and (max-width:602px) {
    .area-research .search-area {
      width: auto;
    }    
  }
  .jsmb-wrap {
    flex-wrap: wrap
  }
  .jsmb-wrap .form-top-item {
    width: 33%
  }
</style>
<style>
  .b-checkbox.checkbox input[type=checkbox]:checked + .check{
    background: crimson !important;
  }
  input[type=checkbox] + .check {
    border-color:#df5051
  }
  .b-checkbox.checkbox input[type=checkbox] + .check {
    border-color:#df5051 !important
  }
  .checkbox input[type=checkbox]:checked + .check {
    background:#df5051;
  }
  input:not([type="checkbox"]){
    margin-bottom: 0.5rem;
    border:none;
  }
</style>
