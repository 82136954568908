<template>
    <div>
        <div class="fb-login-button"
            data-size="large"
            data-button-type="login_with"
            data-auto-logout-link="false"
            data-use-continue-as="false"
            data-scope="public_profile,email"
        ></div>
        <p class="help is-danger" :class="{'is-hidden': notError}">{{errorMsg}}</p>
    </div>
</template>

<script>
    import { STATE_MODAL } from '@/store/modules/landing/actions/landing.actions'
    import { FACEBOOK_AUTH_REQUEST } from '@/store/modules/entrance/actions/entrance.actions'
    
    export default {
        name: 'FacebookLogin',
        data(){
            return {
                notError: true,
                errorMsg: '',
            }
        },
        methods: {
            onLogin(fbLoginResp) {
                const { id, name, email, first_name, last_name } = fbLoginResp
                this.$store.dispatch(
                    FACEBOOK_AUTH_REQUEST, { id, name, email, first_name, last_name }
                ).then(
                    resp => this.onLoginSucceed(resp)
                ).catch(
                    error => this.onLoginFailed(error)
                )
            },
            onLoginSucceed (resp) {
                const {success, msg, data} = resp.data.response
                if (success) {
                    this.$store.dispatch(STATE_MODAL, {'show_modal': false})
                }else{
                    if (data.email_free) {
                        this.$emit('signup-fb-init')
                    }else{
                        this.notError = false;
                        this.errorMsg = msg['email'][0];
                    }
                    
                }
            },
            onLoginFailed (error) {
                console.log('ERROR : ', error)
                this.$store.dispatch(STATE_MODAL, {'show_modal': false})
            }
        },
        created () {
            var fbVueComponent = this;
            window.fbAsyncInit = function() {
                FB.init({
                    appId            : '490013759543297',
                    autoLogAppEvents : true,
                    xfbml            : true,
                    version          : 'v3.2'
                });
            }

            (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return;}
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/fr_FR/sdk.js";
            //js.src = "https://connect.facebook.net/fr_FR/sdk/debug.js";
            fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));

            FB.Event.subscribe('auth.statusChange',
                function(respEvent) {
                    if (respEvent.status === 'connected') {
                        // Logged into your app and Facebook.
                        FB.api('/me', 'GET', { fields: 'id,name,email,first_name,last_name' }, function(respApi) {
                            //console.log(respApi);
                            fbVueComponent.onLogin(respApi);
                        });
                    }else if (respEvent.status === 'not_authorized') {
                        // The user hasn't authorized your application.  They
                        // must click the Login button, or you must call FB.login
                        // in response to a user gesture, to launch a login dialog.
                        //console.log(respEvent);
                    } else {
                        // The person is not logged into this app or we are unable to tell.
                        fbVueComponent.onLoginFailed(respEvent);
                    }
                }
            );
        }
    }
</script>
