<template>
    <div class="navbar-brand">
      <div class="columns is-mobile is-vcentered" :class=" displayOnTouch ? 'mobile-height' : ''">
          <div :class="displayOnTouch ? '' : 'column'">
            <router-link class="navbar-item jsmb-logo" :to="{name:'Landing'}" tag="a" push>
                <img src="./../../../../assets/logo.svg" class="image is-128x128">
            </router-link>
          </div>
          <div :class="displayOnTouch ? '' : 'column is-10'">
            <!-- <div class="" v-on:click="goToHomePage" v-if="!isAuthenticated"> -->
            <div class="control has-icons-right" v-if="$route.name === 'Research' || $route.name === 'Researchcity' || $route.name === 'Detail'">
              <vue-google-autocomplete
                ref="google_maps_address"
                id="google_maps"
                classname="input is-small has-background-light is-active nav-rechercher"
                placeholder="Rechercher une adresse..."
                v-on:placechanged="getAddressData"
                types="(cities)"
                :country="country"
                :enable-geolocation="true"
              />
              <span class="icon is-medium is-right">
                <i class="fas fa-search"></i>
              </span>
            </div>
            <div class="" v-on:click="goToHomePage" v-else>
              <router-link class="navbar-item jsmb-logo" :to="{name:'Landing'}" push>
                <!-- <LogoBranding v-if="$mq != 'mobile'" size="is-size-3"/> -->
                <LogoBranding size="is-size-3"/>
              </router-link>
              <p v-if="!displayOnTouch" class="is-italic has-text-text is-size-7">Le spécialiste du stockage de bateaux chez les particuliers et les professionnels</p>
            </div>
          </div>
      </div>
    </div>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import LogoBranding from '@/components/template/ui/LogoBranding.vue'
import { SET_LOCALISATION } from '@/store/modules/landing/actions/landing.actions'
import { mapGetters } from 'vuex'

export default {
  name: 'Branding',
  components: {LogoBranding, VueGoogleAutocomplete},
  data () {
    return {
      country: []//['FR', 'GP', 'MQ', 'GF', 'RE', 'YT', 'PM', 'BL', 'MF', 'WF', 'PF', 'NC']
    }
  },
  methods: {
    goToHomePage: function () {
      console.log('clicked')
      this.$router.push('/')
    },
    getAddressData: function (addressData, placeResultData, id) {
      this.$store.dispatch(SET_LOCALISATION, addressData)
      this.$router.push({ name: 'Researchcity', params: { city: addressData.locality.toLowerCase() } })
    },
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
}
</script>

<style scoped lang="css">
.navbar-brand {
  max-height: 4rem;
}
.navbar-brand .control{
  width:25rem;
  /* max-width: 20rem; */
  min-width: 15rem;
}
.image.is-128x128 {
  width: 88px;
}
.navbar-brand .control input, .navbar-brand .control span {
  padding: 1.5rem
}
.navbar-brand .control input.is-active {
  border-color: rgba(49, 49, 49, 0.2);
  box-shadow: 0 0 0 .5px rgba(20, 172, 194, 0.25)
}
.mobile-height {
  height: 5rem;
}
.jsmb-logo {
  padding:0
}
.navbar-item img  {
  max-height: 3.75rem;
}
.nav-rechercher input {
  margin-bottom: 0;
}
@media screen and (max-width:1070px){
  .navbar-brand .control{
    width:25rem;
  }
}
@media screen and (max-width:670px){
  .navbar-brand .control{
    width:100%;
  }
  .navbar.has-background-light.navbar-shadow.navbar-touch {
    padding: 0.4rem !important
  }
}
@media screen and (max-width:375px) and (orientation:portrait){
  .navbar-brand .control {
    padding: 0 0 0 1rem;
  }
  .navbar-item img  {
    max-height: 2.75rem;
  }
  .image.is-128x128 {
    width: 28px;
  }
}
</style>
