<template>
    <div class="confidentialite">
        
        <router-link :to="{name:'Legal'}" tag="a"><i class="fas fa-user-secret"></i> Confidentialité, Conditions…</router-link>
    </div>
</template>
<script>
export default {
    
}
</script>

<style scoped>
    .confidentialite {
        background:#fff;
        position:fixed;
        bottom:1rem;
        left: 1rem;
        width:max-content;
        padding: 0.5rem 1rem;
        box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
        font-size: 0.8rem;
        border-radius:4px;
        z-index: 999;
        display:none;
    }
    @media screen and (max-width:743px) {
        .confidentialite {
            display:none
        }
    }
    
</style>
