<template>
  <footer class="has-test-white" :class="displayOnTouch ? 'footer-mobile' : 'footer'">
    <div :class="displayOnTouch ? '' : 'columns'">
      <div class="brand-column column is-3">
        <Logo class="logo-svg" color="white"/>
        <!-- <p class="logo-title has-text-weight-regular has-text-white is-size-4 is-italic">jestocke<span class=" has-text-weight-bold">monbateau</span></p> -->
        <figure class="image jsmb-branding-footer">
            <img :src="require('./../../../assets/jsmb-logo-white.svg')" alt="JeStockeMonBateau">
        </figure>
        <div class="brand-contact">
          <p class="is-uppercase has-text-white has-text-weight-bold is-size-6">Contactez-nous</p>
          <a class="is-size-6 has-text-white" href="mailto:contact@jestockemonbateau.fr">contact@jestockemonbateau.fr</a>
          <div class="is-vcentered brand-contact-social">
            <p class="is-uppercase has-text-white has-text-weight-bold is-size-6">Suivez-nous</p>
            <Social size="is-small" color="is-white" class="brand-contact-social"/>
          </div>
        </div>
      </div>
      <div class="column sitemap" :class="{ 'is-7' : !displayOnTouch }">
        <div class="is-size-7 has-text-white" :class="displayOnTouch ? '' : 'columns'">
          <div class="column">
            <!-- <div>
              <h3 class="is-uppercase has-text-weight-bold is-size-5">A propos</h3>
              <a class="has-text-white">Qui sommes-nous ?</a>
              <a class="has-text-white">Devenir bateau-stockeur</a>
            </div> -->

              <h3 class="is-uppercase has-text-weight-bold is-size-5">Stockage</h3>
              <!-- <a class="has-text-white">Costockage</a> -->
              <router-link class="has-text-white" :to="{name:'Howitworks'}" tag="a" push>
                Comment ça marche ?
              </router-link>
              <router-link class="has-text-white" :to="{name:'Rules'}" tag="a" push>
                Stockage entre particuliers
              </router-link>
              <router-link class="has-text-white" to="/tarifs" tag="a" push>
                Tarif stockage bateau
              </router-link>
              <router-link class="has-text-white" :to="{name:'Cancel'}" tag="a" push>
                Modification et annulation
              </router-link>
              <router-link class="has-text-white" :to="{name:'Insurance'}" tag="a" push>
                Assurance
              </router-link>
              <!-- <a class="has-text-white">Assurance et sécurité</a> -->


          </div>
          <div class="column jsmb-stockage-link">
            <h3 class="is-uppercase has-text-weight-bold is-size-5">Stockage bateau</h3>
            <router-link :to="{ name: 'Researchcity', params: { city: 'marseille' } }" push> Stockage bateau Marseille</router-link>
            <router-link :to="{ name: 'Researchcity', params: { city: 'cassis' } }" push> Stockage bateau cassis</router-link>
            <router-link :to="{ name: 'Researchcity', params: { city: 'la-ciotat' } }" push> Stockage bateau La Ciotat</router-link>
            <router-link :to="{ name: 'Researchcity', params: { city: 'toulon' } }" push> Stockage bateau Toulon</router-link>
            <router-link :to="{ name: 'Researchcity', params: { city: 'cannes' } }" push> Stockage bateau Cannes</router-link>
            <router-link :to="{ name: 'Researchcity', params: { city: 'nice' } }" push> Stockage bateau Nice</router-link>
            <router-link :to="{ name: 'Researchcity', params: { city: 'saint-tropez' } }" push> Stockage bateau Saint-Tropez</router-link>
            <router-link :to="{ name: 'Researchcity', params: { city: 'montpellier' } }" push> Stockage bateau Montpellier</router-link>
            <router-link :to="{ name: 'Researchcity', params: { city: 'perpignan' } }" push> Stockage bateau Perpignan</router-link>
            <router-link :to="{ name: 'Researchcity', params: { city: 'bordeaux' } }" push> Stockage bateau Bordeaux</router-link>
            <router-link :to="{ name: 'Researchcity', params: { city: 'nantes' } }" push> Stockage bateau Nantes</router-link>
            <router-link :to="{ name: 'Researchcity', params: { city: 'ajaccio' } }" push> Stockage bateau Ajaccio</router-link>
          </div>
          <div class="column">
            <h3 class="is-uppercase has-text-weight-bold is-size-5">JeStockeMonBateau</h3>
            <!-- <a class="has-text-white">Comment ça marche ?</a> -->
            <router-link class="has-text-white" :to="{name:'Legal'}" tag="a">Politique d'utilisation</router-link>
            <router-link class="has-text-white" :to="{name:'Legal'}" tag="a">Confidentialité, Conditions d'utilisation</router-link>
            <!-- <a class="has-text-white">Stockage professionnel</a> -->
            <router-link class="has-text-white" :to="{name:'Press'}" tag="a" push>Presse</router-link>
            <router-link class="has-text-white" :to="{path:'contact'}" tag="a" push>
              Contact
            </router-link>
            <br>
            <h3 class="is-uppercase has-text-weight-bold is-size-5" style="padding-bottom:0">Paiement sécurisé</h3>
            <figure class="image jsmb-branding-footer-partners anpm">
              <img :src="require('./../../../assets/visaMC.png')" alt="Paiement sécurisé">
            </figure>
          </div>
        </div>
      </div>
      <div class="column" :class="{ 'is-3' : !displayOnTouch}">
        <h2 class="has-text-white is-uppercase has-text-weight-bold is-size-5">Nos partenaires</h2>
        <div class="nos-partenaires">
          <!-- <figure class="image jsmb-branding-footer-partners laProvence">
            <a href="https://www.laprovence.com/" target="_blank">
              <img :src="require('./../../../assets/la-provence.png')" alt="Nos partenaires - La Provence">
            </a>
          </figure> -->
          <figure class="image jsmb-branding-footer-partners stripe">
            <a href="https://stripe.com/" target="_blank">
              <img :src="require('./../../../assets/stripe.png')" alt="Nos partenaires - Stripe">
            </a>
          </figure>
          <figure class="image jsmb-branding-footer-partners anpm">
            <a href="https://www.anpm.fr/" target="_blank">
              <img :src="require('./../../../assets/anpm.png')" alt="Nos partenaires - ANPM">
            </a>
          </figure>
          <figure class="image jsmb-branding-footer-partners clickandboat">
            <a href="https://www.clickandboat.com" target="_blank">
              <img :src="require('./../../../assets/clickandboat.svg')" alt="Nos partenaires - Click and Boat">
            </a>
          </figure>
          <figure class="image jsmb-branding-footer-partners axa">
            <a href="https://www.axa.fr/" target="_blank">
              <img :src="require('./../../../assets/axa.png')" alt="Nos partenaires - AXA">
            </a>
          </figure>
        </div>
      </div>
    </div>
    <Confidentialite/>
    <Rgpd/>
  </footer>
</template>

<script>
import Logo from '@/components/template/ui/Logo.vue'
import Confidentialite from '@/components/template/footer/Confidentialite.vue'
import Social from '@/components/template/ui/Social.vue'
import Rgpd from '@/components/template/footer/RGPD.vue'

export default {
  name: 'Footer',
  components: {
    Logo,
    Social,
    Confidentialite,
    Rgpd
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  .footer{
    background-color: #313131;
    position:relative;
    z-index: 12;
  }
  .footer-mobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 0 !important;
    text-align: center;
    background-color: #313131;
  }
  .footer .columns{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0rem 2rem;
  }
  /*Brand colulmn*/
  .footer .brand-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
    .footer-mobile .brand-column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: auto;
    }
  .footer .brand-column .logo-svg{
    width: 3rem;
    margin-left: 4.5rem;
  }
    .footer-mobile .brand-column .logo-svg{
      width: 2.5rem;
      min-width: 2.5rem;
    }
  .footer .brand-column .logo img, .footer-mobile .brand-column .logo img{
    fill: currentColor;
  }
  .footer .brand-column .logo-title span, .footer-mobile .brand-column .logo-title span{
      font-style:initial !important;
  }
  .footer .brand-contact {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 1rem;
  }
    .footer-mobile .brand-contact {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 1rem;
    }
  .jsmb-branding-footer {
    width: 200px;
  }
  figure.jsmb-branding-footer-partners {
    margin-top: 1rem;
    filter: grayscale(100);
    transition: all ease-in-out 0.5s;
  }
  .jsmb-branding-footer-partners:hover {
    filter: grayscale(0);
  }
  @media screen and (max-width:1049px){
    figure.jsmb-branding-footer-partners {
      margin: 1rem auto;
    }
  }
  /* figure.jsmb-branding-footer-partners{
    margin: 1rem auto;
  } */
  figure.jsmb-branding-footer-partners.laProvence {
      width: 120px;
  }
  figure.jsmb-branding-footer-partners.stripe {
      width: 80px;
  }
  figure.jsmb-branding-footer-partners.anpm {
      width: 70px;
  }
  figure.jsmb-branding-footer-partners.axa {
      width: 70px;
  }
  figure.jsmb-branding-footer-partners.clickandboat {
      width: 120px;
  }
  .footer .brand-contact p:first-of-type, .footer .brand-contact a:first-of-type,
  .footer-mobile .brand-contact p:first-of-type, .footer-mobile .brand-contact a:first-of-type{
    padding-top: 1rem;
  }
  .footer .brand-contact .brand-contact-social:first-of-type{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: baseline;
    margin-top: 1rem;
  }
  .footer-mobile .brand-contact .brand-contact-social:first-of-type{
    margin: 1rem auto;
  }
  .jsmb-stockage-link a {
    color:#ffffff
  }
  /* Sitemap */
  .footer .sitemap .column h3{
    padding-bottom: 1rem;
  }
  .footer .sitemap .columns:nth-child(0n+1) .column:nth-child(0n+1){
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .sitemap .columns:nth-child(0n+1) .column:nth-child(0n+1) div:last-of-type,
  .footer-mobile .sitemap .column:nth-child(0n+1) div:last-of-type{
    padding-top: 1rem;
  }
  .sitemap .column:nth-child(0n+2) a::before{
    content: '-';
    padding-right: 0rem;
  }
  .sitemap .column a{
    display: block;
  }
  .footer-mobile .sitemap .column{
    padding-top: 1rem;
  }
  .footer-mobile .column:last-of-type{
    padding-top: 1rem;
    margin: auto;
  }
  /* .nos-partenaires {
    display: flex;
    flex-direction: column
  } */
</style>
