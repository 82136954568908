<template>
    <div>
        <h2 class="has-text-primary has-text-centered is-uppercase is-size-4 has-text-weight-bold" v-if="currentStep <= 11">Modifier mon mot de passe</h2>
        <h2 class="has-text-primary has-text-centered is-uppercase is-size-3 has-text-weight-bold" v-if="currentStep == 12">Félicitations</h2>

        <form class="field" @submit.prevent="switchStepAccount">
            <div v-if="currentStep == 9">
                <p class="has-margin-top-1">Saisissez votre adresse email</p>
                <div class="field jsmb-email-account">
                    <div class="control has-icons-right">
                        <input class="input" v-model="emailAddress" type="email" placeholder="Votre email" value="Votre email">
                        <span class="icon is-small has-text-primary is-right">
                        <i class="fas fa-envelope"></i>
                        </span>
                    </div>
                </div>
                <p class="help is-danger has-text-centered has-margin-bottom-1" :class="{'is-hidden': notError}">{{errorMsg}}</p>
            </div>

            <div v-if="currentStep == 10">
                <p class="has-margin-top-1 has-margin-bottom-1 has-text-centered">Vous allez recevoir un email contenant un lien pour modifier votre mot de passe</p>
            </div>

            <div v-if="currentStep == 11">
                <p class="has-margin-top-1">Saisissez votre mot de passe</p>
                <b-field>
                    <b-input name="password" type="password" v-model="password" placeholder="Mot de passe" maxlength=20 password-reveal required></b-input>
                </b-field>
                <p class="help is-danger has-text-centered has-margin-bottom-1" :class="{'is-hidden': notError}">{{errorMsg}}</p>
            </div>

            <div v-if="currentStep == 12">
                <p class="has-margin-top-1 has-margin-bottom-1 has-text-centered">Votre mot de passe a bien été modifié !</p>
            </div>

            <div class="has-text-centered">
                <button v-if="buttonSendCenter" type='submit' class="button is-danger ">Envoyer</button>
                <button v-if="buttonDoneCenter" type='button' v-on:click="closeModal" class="button is-danger ">Fermer</button>
            </div>
        </form>
    </div>
</template>
<script>
import { FORGOTTEN_REQUEST, FORGOTTEN_CHANGE } from '@/store/modules/entrance/actions/entrance.actions'

export default {
    name: 'Forgotten',

    data() {
        return {
            buttonSendCenter: false,
            buttonDoneCenter: false,

            notError: true,
            errorMsg: '',
            
            emailAddress: '',
            password:''
        }
    },
    props: {
        currentStep: {
            type:Number,
        }
    },
    methods: {
        closeModal () {
            this.$emit('close-modal')
        },
        updateShowButton (sendCenter, doneCenter) {
            this.buttonSendCenter = sendCenter
            this.buttonDoneCenter = doneCenter
        },
        switchShowButton (currentStep) {
            switch (currentStep) {
                case 9:
                    this.updateShowButton(true, false)
                    break;

                case 10:
                    this.updateShowButton(false, true)
                    break;

                case 11:
                    this.updateShowButton(true, false)
                    break;

                case 12:
                    this.updateShowButton(false, true)
                    break
            }
        },
        switchStepAccount() {
            switch (this.currentStep) {
                case 9:
                    const { emailAddress } = this
                    this.$store.dispatch(
                        FORGOTTEN_REQUEST,
                        { emailAddress }
                    ).then(
                        resp => {
                            this.$emit('change-current-step', true, false)
                            this.switchShowButton(this.currentStep+1)
                            this.notError = true
                            this.errorMsg = ''
                        }
                    ).catch(
                        error => {
                            const { msg } = error.data.response
                            this.notError = false
                            this.errorMsg = msg
                        }
                    )
                    break;

                case 11:
                    const token = localStorage.getItem('forgottenPasswordToken')
                    const { password } = this
                    this.$store.dispatch(
                        FORGOTTEN_CHANGE,
                        { token, password }
                    ).then(
                        resp => {
                            this.$emit('change-current-step', true, false)
                            localStorage.removeItem('forgottenPasswordToken')
                            this.switchShowButton(this.currentStep+1)
                            this.notError = true
                            this.errorMsg = ''
                        }
                    ).catch(
                        error => {
                            const { msg } = error.data.response
                            this.notError = false
                            this.errorMsg = msg['password'][0]
                        }
                    )
                    break;
            }
        },
    },
    created () {
        this.switchShowButton (this.currentStep)
    }
}
</script>

<style scoped>
  .has-margin-bottom-1 {
    margin-bottom: 1rem;
  }
  .has-margin-top-1 {
    margin-top: 1rem;
  }
.buttons.account-steps {
    /* position: absolute;
    bottom:0;
    width: 100%; */
    justify-content: space-between;
}
@media screen and (max-width:768px) {
    .buttons.account-steps {
    position: relative;
    margin-top: 1.5rem;
    }
    .modal-card {
    height: auto;
    }
}
</style>
