<template>
  <div id="app">
    <Navigation :key="isAuthenticated" />
    <div>
      <transition name="fade" mode="out-in">
        <router-view :key="$route.params || $route.query" />
      </transition>
      <b-loading
        class="jsmb-fullPage"
        :is-full-page="true"
        :active.sync="globalLoading"
      ></b-loading>
    </div>
    <b-modal :active.sync="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="bgModal">
          <h2
            class="
              has-text-weight-bold
              is-size-4
              has-text-centered
              is-uppercase
              has-text-white
            "
          >
            Malheureusement, votre navigateur n'est pas<br />compatible avec
            notre site.
          </h2>
        </div>
        <div class="card-content">
          <div class="content">
            <p>
              Afin de bénéficier
              <strong>d'une meilleure expérience utilisateur</strong> mettez
              votre navigateur à jour ou téléchargez en un plus récent.
            </p>
            <div class="columns has-text-centered">
              <div class="column">
                <a
                  href="https://www.mozilla.org/fr/firefox/new/"
                  target="_blank"
                >
                  <img
                    :src="require('./assets/firefox.png')"
                    alt="Téléchargez Firefox"
                  />
                </a>
                <br />
                <a
                  href="https://www.mozilla.org/fr/firefox/new/"
                  class="button is-small"
                  target="_blank"
                  >Firefox</a
                >
              </div>
              <div class="column">
                <a
                  href="https://www.google.com/intl/fr_fr/chrome/"
                  target="_blank"
                >
                  <img
                    :src="require('./assets/chrome.png')"
                    alt="Téléchargez Chrome"
                  />
                </a>
                <br />
                <a
                  href="https://www.google.com/intl/fr_fr/chrome/"
                  class="button is-small"
                  target="_blank"
                  >Chrome</a
                >
              </div>
              <div class="column">
                <a
                  href="https://www.microsoft.com/fr-fr/windows/microsoft-edge"
                  target="_blank"
                >
                  <img
                    :src="require('./assets/edge.png')"
                    alt="Téléchargez Edge"
                  />
                </a>
                <br />
                <a
                  href="https://www.microsoft.com/fr-fr/windows/microsoft-edge"
                  class="button is-small"
                  target="_blank"
                  >Edge</a
                >
              </div>
            </div>
            <br />
            <p>
              Vous pouvez quand même naviguer sur notre site avec votre
              navigateur actuel.
            </p>
          </div>
        </div>
      </div>
    </b-modal>
    <Footer />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Navigation from "@/components/template/navigation/Navigation.vue";
import Footer from "@/components/template/footer/Footer.vue";
import { AUTH_CHECK } from "@/store/modules/entrance/actions/entrance.actions";
import { setTimeout } from "timers";

export default {
  name: "App",
  data() {
    return {
      isCardModalActive: false,
    };
  },
  components: {
    Navigation,
    Footer,
  },
  computed: {
    ...mapGetters(["mainLoading", "globalLoading", "isAuthenticated"]),
  },
  metaInfo() {
    let siteName = this.$store.state.globalConstant.SITE_NAME;
    return {
      title: siteName,
      meta: [
        {
          vmid: "keyword",
          name: "keyword",
          content:
            "hivernage, hivernage bateau, stockage bateau, stockage bateau chez particuliers, ports, ports à secs, plaisanciers, bateau-stockeur, bateau-skipper, stockage bateau hors d'eau, bateau, stockage bateau Marseille, stockage bateau Cassis, stockage bateau la Ciotat, stockage bateau Toulon, stockage bateau Cannes, stockage bateau Nice, stockage bateau Saint-Tropez, stockage bateau Montpellier, stockage bateau Perpignan, stockage bateau Bordeaux, stockage bateau Nantes, stockage bateau Ajaccio",
        },
        // OG
        {
          property: "og:locale",
          content: "fr_FR",
          vmid: "og:locale",
        },
        {
          property: "og:type",
          content: "article",
          vmid: "og:type",
        },
        {
          property: "og:sitename",
          content: siteName,
          vmid: "og:sitename",
        },
        {
          property: "og:image:width",
          content: "1200",
          vmid: "og:image:width",
        },
        {
          property: "og:image:height",
          content: "630",
          vmid: "og:image:height",
        },
        //TWITTER CARD
        {
          name: "twitter:card",
          content: "summary",
          vmid: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@jestockemonbateau",
          vmid: "twitter:site",
        },
      ],
    };
  },
  created() {
    // Vérifie le navigateur est IE
    // if (!!window.MSInputMethodContext && !!document.documentMode) {
    //   this.isCardModalActive = true
    // }
    if (navigator.userAgent.indexOf("Safari") != -1) {
      var ua = navigator.userAgent,
        index,
        navigateur,
        version;
      version =
        ua.match(/Version\/([0-9]+(?:\.[0-9]+)*)/)[1] ||
        ua.match(/Safari\/([0-9]+(?:\.[0-9]+)*)/)[1];
      numeroVersion = parseInt(version.trim().split(".")[0]);
      if (numeroVersion <= 9) {
        this.isCardModalActive = true;
      }
    }
    // Clear Cache
    caches.keys().then(function (cacheKeys) {
      cacheKeys.forEach((value, key) => {
        caches.delete(value);
      });
    });

    // Facebook Pixel PageView
    this.$analytics.fbq.event("PageView");
  },
  updated() {
    // setTimeout(()=>{
    //   if (this.$router.currentRoute.name === "Detail") {
    //     document.querySelector('iframe#launcher').style.bottom = 6+'rem';
    //   }
    //   else{
    //     document.querySelector('iframe#launcher').style.bottom = 0+'rem';
    //   }
    // },5000)
    // User is Authenticated ?
    if (this.isAuthenticated) {
      this.$store.dispatch(AUTH_CHECK);
      if (localStorage.getItem("user-type") === null) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  },
};
</script>

<style lang="css">
/* body {
  overflow-x: hidden;
  overflow-y: scroll;
} */

#app {
  background-color: #fff;
}
.jsmb-fullPage .loading-overlay {
  z-index: 1000000 !important;
}
.jsmb-fullPage .loading-overlay .loading-background {
  background: hsla(0, 0%, 100%, 1) !important;
}
.bgModal {
  background: #0ec0b7;
  padding: 1rem 0;
}
</style>

<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";
@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,600");

// Set your colors
// $primary: #27c6bd;
$primary: #0ec0b7;
$primary-invert: findColorInvert($primary);
$danger: #df5051;
$danger-invert: findColorInvert($danger);
// $link: #14acc2;
$link: #0ec0b7;
$link-invert: findColorInvert($link);
$text: #001c20;
$text-invert: findColorInvert($text);
$light: #f2f5fb;
$light-invert: findColorInvert($light);
$grey: #f2f5fb;
$grey-invert: findColorInvert($grey);
$dark: #828282;
$dark-invert: findColorInvert($dark);
$radius: 0px;

// Setup global variables
$family-sans-serif: "Roboto", sans-serif;
$family-primary: $family-sans-serif;
$size-1: 3rem;
$size-2: 2.5rem;
$size-3: 2rem;
$size-4: 1.25rem;
$size-5: 1rem;
$size-6: 0.8rem;
$size-7: 0.7rem;
$column-gap: 0;

// Navbar
$navbar-height: 5rem;
$navbar-breakpoint: 1050px;
$navbar-item-color: $text;
$navbar-item-hover-background-color: transparent;
$navbar-item-hover-color: $primary;
$navbar-dropdown-arrow: $text;
$navbar-dropdown-background-color: $white;
$navbar-dropdown-border-top: 1px solid #eeeeee;
$navbar-dropdown-background-color: $white;
$navbar-dropdown-item-hover-color: light;
//$navbar-dropdown-boxed-shadow	size	0 8px 8px rgba($black, 0.1), 0 0 0 1px rgba($black, 0.1)
//$navbar-dropdown-border-top	size	2px solid $border
//
//$navbar-dropdown-radius	size	$radius-large	6px
//$navbar-dropdown-z	string	20
//
//$navbar-dropdown-item-hover-color	color	$black	hsl(0, 0%, 4%)
//$navbar-dropdown-item-hover-background-color	color	$background	hsl(0, 0%, 96%)
//$navbar-dropdown-item-active-color	color	$link	hsl(217, 71%, 53%)
//$navbar-dropdown-item-active-background-color

// Input
$input-radius: $radius;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  "white": (
    $white,
    $black,
  ),
  "black": (
    $black,
    $white,
  ),
  "light": (
    $light,
    $light-invert,
  ),
  "text": (
    $text,
    $text-invert,
  ),
  "primary": (
    $primary,
    $primary-invert,
  ),
  "dark": (
    $dark,
    $dark-invert,
  ),
  "link": (
    $link,
    $link-invert,
  ),
  "info": (
    $info,
    $info-invert,
  ),
  "success": (
    $success,
    $success-invert,
  ),
  "warning": (
    $warning,
    $warning-invert,
  ),
  "danger": (
    $danger,
    $danger-invert,
  ),
);

$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
</style>
