<template>
  <div class="login-form has-text-centered">
    <div>
      <h2 v-if="!authErrorSubmitAd" class="has-text-primary has-text-centered is-uppercase is-size-3 has-text-weight-bold">Identifiez-vous</h2>
      <h2 v-if="authErrorSubmitAd" class="has-text-primary has-text-centered is-uppercase is-size-4 has-text-weight-bold">Identifiez-vous avant de déposer une annonce</h2>
      <br>
    </div>
    <FacebookLogin @signup-fb-init="$emit('signup-init')"/>
    <GoogleLogin @signup-google-init="$emit('signup-init')"/>
    <p class="has-margin-top-1 has-margin-bottom-1 has-text-centered">ou</p>
    
    <form class="field" @submit.prevent="login">
      <div class="field jsmb-email-account">
        <div class="control has-icons-right">
            <input class="input" v-model="emailAddress" type="email" placeholder="Votre email" value="Votre email" required>
            <span class="icon is-small has-text-primary is-right">
              <i class="fas fa-envelope"></i>
            </span>
        </div>
      </div>
      <b-field>
        <b-input name="password" type="password" v-model="password" placeholder="Mot de passe" minlength=8 maxlength=20 password-reveal required></b-input>
      </b-field>
      <p class="help is-danger has-text-centered has-margin-bottom-1" :class="{'is-hidden': notError}">{{errorMsg}}</p>
      <div class="has-text-left has-margin-bottom-1">
        <b-checkbox v-model="rememberMe">Se souvenir de moi</b-checkbox>
      </div>
      <div class="control">
        <button class="button has-text-weight-semibold is-size-5 is-primary is-fullwidth">
          <span>Connexion</span>
          <b-icon pack="fas" icon="arrow-right" size="is-small"></b-icon>
        </button>
      </div>
    </form>
    <p class="has-text-grey has-margin-bottom-1">
      <a @click="showForgottenPassword">Mot de passe oublié ?</a>
    </p>
    <p class="has-text-grey">
      Vous n'avez pas de compte ?<a @click="showSignUp" class="has-text-danger has-text-weight-bold"> Inscription</a>
    </p>
  </div>
</template>

<script>
import { AUTH_REQUEST } from '@/store/modules/entrance/actions/entrance.actions'
import { STATE_MODAL } from '@/store/modules/landing/actions/landing.actions'
import FacebookLogin from '@/components/template/ui/FacebookLogin.vue'
import GoogleLogin from '@/components/template/ui/GoogleLogin.vue'

export default {
  name: 'Login',
  components: {
    FacebookLogin,
    GoogleLogin
  },
  data () {
    return {
      emailAddress: localStorage.getItem('emailAddress') || '',
      password: '',
      rememberMe: false,
      notError: true,
      errorMsg: '',
    }
  },
  props: {
    authErrorSubmitAd: {type: Boolean},
  },
  methods: {
    showSignUp() {
      this.$emit('showSignUp')
    },
    showForgottenPassword() {
      this.$emit('showForgottenPassword')
    },
    login () {
      const { emailAddress, password, rememberMe } = this
      this.$store.dispatch(AUTH_REQUEST, { emailAddress, password, rememberMe })
        .then(resp => this.loginSucceed(resp))
        .catch(error => this.loginFailed(error))
    },
    loginSucceed () {
      this.notError = true
      this.errorMsg = ''
      this.$store.dispatch(STATE_MODAL, {'show_modal': false})
      this.$router.go()
    },
    loginFailed (error) {
      const {data, msg} = error.data.response      
      this.notError = false
      if (!data) {
        switch (msg) {
          case 'Invalid email.':
            this.errorMsg = 'Attention cette adresse ne correspond à aucun compte. Inscrivez-vous !'
            break;

          case 'Invalid credentials.':
            this.errorMsg = 'Le mot de passe entré est incorrect.'
            break;
        
          default:
            this.errorMsg = 'Une erreur s\'est produite.'
            break;
        }
      }else if (typeof data.email_verify !== 'undefined') {
        this.errorMsg = 'Votre compte n\'est pas activé.'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
.login-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.has-margin-bottom-1 {
  margin-bottom: 1rem;
}
.has-margin-top-1 {
  margin-top: 1rem;
}
</style>
