<template>
  <div>
    <figure class="image jsmb-branding">
        <img :src="require('./../../../assets/jsmb-logo.svg')" alt="JeStockeMonBateau">
    </figure>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  props: {
    size: {
      type: String,
      default: 'is-size-3 '
    }
  }
}
</script>

<style scoped lang="css">
.logo-title span{
    font-style:initial !important;
}
.jsmb-branding img {
  max-width: 280px;
  min-width: 280px;
  max-height: 50px;
}
@media screen and (max-width: 580px) {
  .jsmb-branding img {
  max-width: 220px;
  min-width: 220px;
  max-height: 50px;
}
}
</style>
