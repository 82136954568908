<template>
    <div class="rgpd" v-show="isShow">
        <button @click="isShow = false" class="delete" aria-label="close"></button>
        <p>Nous utilisons des cookies pour vous offrir la meilleure expérience en ligne. En acceptant, vous acceptez l'utilisation de cookies conformément à notre politique de confidentialité des cookies.</p>
        <div class="btnContainer">
            <a @click="approved" class="button is-info ">J'accepte</a>
            <a class="button is-danger">Je refuse</a>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Rgpd',
    data(){
        return {
            isShow: true
        }
    },
    created() {
        if (JSON.parse(localStorage.getItem('rgpd-show')) === true || this.$store.getters.isAuthenticated) {
            this.isShow = false
        }
    },
    methods: {
        approved () {
            this.isShow = false;
            localStorage.setItem('rgpd-show', true)
        }
    },
    computed: {
        ...mapGetters(['isAuthenticated'])
    },
}
</script>
<style scoped lang="css">
    .rgpd {
        position: fixed;
        right:1rem;
        bottom:1rem;
        z-index: 999999;
        background:#333333;
        padding:1rem;   
        max-width: 25rem;
        color:#fff
    }
    @media screen and (max-width: 425px){
        .rgpd {
            right:0rem;
            bottom:0rem;
        }   
    }
    .delete {
        background:#000;
        position: absolute;
        top:-0.5rem;
        left:-0.5rem;
        z-index: 9;
    }
    .delete:hover {
        background:#df5051;
    }
    p {
        margin-bottom: 1.5rem;
        font-size: 0.8rem
    }
    .btnContainer {
        display: flex;
        justify-content: space-between;
    }
</style>