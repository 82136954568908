<template>
    <div class="google-btn">
        <g-signin-button
            :params="googleSignInParams"
            @success="onLogin"
            @error="onLoginFailed" class="button btn-google">
            <span class="icon">
                <i class="fab fa-google"></i>
            </span>
            <span>Se connecter avec Google</span>
        </g-signin-button>
        <p class="help is-danger" :class="{'is-hidden': notError}">{{errorMsg}}</p>
    </div>
</template>

<script>
    import { STATE_MODAL } from '@/store/modules/landing/actions/landing.actions'
    import { GOOGLE_AUTH_REQUEST } from '@/store/modules/entrance/actions/entrance.actions'
    
    export default {
        name: 'GoogleLogin',
        data(){
            return {
                googleSignInParams: {
                    client_id: '731877983875-i7jr8clia7krmki1i0nv1e5cp567ok6j.apps.googleusercontent.com'
                },
                notError: true,
                errorMsg: '',
            }
        },
        methods: {
            onLogin(googleUser) {
                // `googleUser` is the GoogleUser object that represents the just-signed-in user.
                // See https://developers.google.com/identity/sign-in/web/reference#users
                const profile = googleUser.getBasicProfile()
                let id = profile.getId(),
                    name = profile.getName(),
                    email = profile.getEmail(),
                    first_name = profile.getGivenName(),
                    last_name = profile.getFamilyName(),
                    imageUrl = profile.getImageUrl()

                this.$store.dispatch(
                    GOOGLE_AUTH_REQUEST, { id, name, email, first_name, last_name, imageUrl }
                ).then(
                    resp => this.onLoginSucceed(resp)
                ).catch(
                    error => this.onLoginFailed(error)
                )
            },
            onLoginSucceed (resp) {
                const {success, msg, data} = resp.data.response
                if (success) {
                    this.$store.dispatch(STATE_MODAL, {'show_modal': false})
                }else{
                    if (data.email_free) {
                        this.$emit('signup-google-init')
                    }else{
                        this.notError = false;
                        this.errorMsg = msg['email'][0];
                    }
                    
                }
            },
            onLoginFailed (error) {
                // `error` contains any error occurred.
                console.log('ERROR : ', error)
                this.$store.dispatch(STATE_MODAL, {'show_modal': false})
            }
        }
    }
</script>

<style scoped lang="css">
    .google-btn {
        margin-top: 1.2rem;
    }
    .g-signin-button {
        /* This is where you control how the button looks. Be creative! */
        display: inline-block;
        padding: 4px 8px;
        border-radius: 3px;
        background-color: #3c82f7;
        color: #fff;
        box-shadow: 0 3px 0 #0f69ff;
        margin-top: 5px;
        cursor: pointer;
    }
    .btn-google,
    .btn-google:hover {
        background-color: #3c82f7;
        color:#fff;
        font-family: Helvetica, Arial, sans-serif;
        letter-spacing: .25px;
        font-weight: bold;
        border: 2px solid #3c82f7;
        height: 40px;
    }
    .btn-google span {
        font-size: 16px;
        margin: 0 24px 0 12px;

    }
</style>