<template>
  <div :class="color">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89.81 125.66">
      <title>Logo</title>
      <path class="fill" d="M86.73,77.3a3.08,3.08,0,0,0-3.07,3.08,38.73,38.73,0,0,1-69.18,24l7-20.83,49.78.12h0a3.08,3.08,0,0,0,2.92-4.06L48,2.09A3.07,3.07,0,0,0,45.11,0h0a3.08,3.08,0,0,0-2.91,2.1L17,77.34l-13.94,0h0a3.11,3.11,0,0,0-.62.06c-.1,0-.19.06-.29.09a2.46,2.46,0,0,0-.29.09l-.32.17-.2.11c-.1.07-.18.15-.27.22L.9,78.2a2.43,2.43,0,0,0-.2.25c-.06.07-.12.13-.17.2s-.1.18-.15.27-.1.17-.14.26,0,.18-.08.27a1.76,1.76,0,0,0-.1.3c0,.1,0,.2,0,.29s0,.22,0,.33H0a44.68,44.68,0,0,0,7.63,25L2.21,121.61a3.07,3.07,0,0,0,1.94,3.89,3,3,0,0,0,1,.16A3.07,3.07,0,0,0,8,123.56l4.17-12.45a44.87,44.87,0,0,0,77.6-30.73A3.08,3.08,0,0,0,86.73,77.3ZM45.12,12.73,67,77.46,23.5,77.35ZM6.28,83.46l8.69,0L10.23,97.65A38.64,38.64,0,0,1,6.28,83.46Z"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  props: {
    color: {
      type: String,
      default: 'text'
    }
  }
}
</script>

<style scoped lang="css">
.white {
  color: #fff;
}
.primary {
  color: #27c6bd;
}
.link {
  color: #14acc2;
}
.text {
  color: #001c20;
}
.light {
  color: #f2f5fb;
}
.grey {
  color: #f2f5fb;
}
.dark {
  color: #828282;
}
.fill{
  fill: currentColor;
}
</style>
