<template>
    <nav class="navbar is-fixed-top has-background-light" :class="[ this.$route.name != 'Landing'? 'navbar-shadow': '', displayOnTouch ? 'navbar-touch' : 'navbar-not-touch']" role="navigation" aria-label="main navigation">

        <div class="navbar-layer" :class="{ 'is-active' : isActive }"></div>

        <div class="navbar-brand">
            <Branding/>
            <a role="button" class="navbar-burger burger has-text-primary" aria-label="menu" aria-expanded="true" data-target="navbar" :class="{ 'is-active ': isActive }" @click="displayMobileNav">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
        </div>

        <div id="navbar" class="navbar-menu" :class="{ 'is-active' : isActive }">
          <a role="button" class="navbar-burger burger has-text-primary" aria-label="menu" aria-expanded="true" data-target="navbar" :class="{ 'is-active ': isActive }" @click="displayMobileNav">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
          </a>
            <div class="navbar-end is-size-6" :class="displayOnTouch ? '' : 'is-uppercase'">
              <LogoBranding v-if="displayOnTouch" size="is-size-4"/>
              <div class="navbar-item navbar-letterspacing">
                <router-link class="navbar-item" :to="{name: 'Research'}" tag="a" v-on:click.native="isActive = false" push>Rechercher</router-link>
                <router-link class="button is-danger is-small" :to="{name: 'Wizard'}" tag="a" v-on:click.native="submitAnnouncement($event)" push>Déposer mon annonce</router-link>
              </div>
              <div class="navbar-item navbar-help-item has-dropdown is-hoverable">
                  <a class="navbar-link" @click="displayDropdown">Aide</a>
                  <div class="navbar-dropdown is-active is-size-7-touch" v-if="!displayOnTouch || isOpen">
                      <router-link class="navbar-item" :to="{name:'Howitworks'}" tag="a" v-on:click.native="isActive = false" push>Comment ça marche</router-link>
                      <router-link class="navbar-item" :to="{name:'Rules'}" tag="a" v-on:click.native="isActive = false" push>Stockage entre particuliers</router-link>
                      <router-link class="navbar-item" :to="{name:'Prices'}" tag="a" v-on:click.native="isActive = false" push>Tarifs</router-link>
                      <router-link class="navbar-item" :to="{name:'Insurance'}" tag="a" v-on:click.native="isActive = false" push>Assurance</router-link>
                      <router-link class="navbar-item" :to="{name:'Cancel'}" tag="a" v-on:click.native="isActive = false" push>Modification et annulation</router-link>
                      <!-- <a class="navbar-item">Assurance & sécurité</a> -->
                      <router-link class="navbar-item" :to="{name:'Contact'}" tag="a" v-on:click.native="isActive = false" push>Contact</router-link>
                  </div>
              </div>
              <Connect @open-modal="openModal" @close-mobile-nav="isActive = false"/>
              <Social v-if="this.$mq == 'desktop'" class="navbar-item" color="is-text" size="is-small"/>
            </div>
        </div>

        <b-modal :active.sync="modalState" has-modal-card @close="closeModal" id="modal-login">
          <Entrance :modalSignUpDone="modalSignUpDone" @modal-sign-up-no-show="modalSignUpNoShow" :modalForgotten="modalForgotten" @modal-forgotten-no-show="modalForgottenNoShow" :authErrorSubmitAd="authErrorSubmitAd"/>
        </b-modal>

    </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '../../../store'
import Branding from '@/components/template/navigation/nav-sections/Branding.vue'
import LogoBranding from '@/components/template/ui/LogoBranding.vue'
import Connect from '@/components/template/navigation/nav-sections/Connect.vue'
import Social from '@/components/template/ui/Social.vue'
import Entrance from '@/components/entrance/Entrance.vue'
import { STATE_MODAL } from '@/store/modules/landing/actions/landing.actions'

export default {
  name: 'Navigation',

  components: {
    Branding,
    LogoBranding,
    Connect,
    Social,
    Entrance
  },

  data () {
    return {
      isOpen: true,
      authErrorSubmitAd: false,
      isActive: false,
      modalSignUpDone: false,
      modalForgotten : false,
    }    
  },

  methods: {

    displayMobileNav: function () {
      this.$data.isActive = this.$data.isActive ? false : true;
      this.$data.isOpen = false;
    },

    displayDropdown: function () {
      this.$data.isOpen = this.$data.isOpen ? false : true;
    },

    openModal: function () {
      this.$data.isActive = false;
      this.$store.dispatch(STATE_MODAL, {'show_modal': true})
    },

    modalSignUpNoShow: function () {
      this.modalSignUpDone = false;
    },

    closeModal () {
      this.authErrorSubmitAd = false
      this.$store.dispatch(STATE_MODAL, {'show_modal': false})
    },

    modalForgottenNoShow: function () {
      this.modalForgotten = false;
    },

    submitAnnouncement: function (event) {
      this.isActive = false
      if (!store.getters.isAuthenticated) {
        event.preventDefault()
        this.$store.dispatch(STATE_MODAL, {'show_modal': true})
        this.authErrorSubmitAd = true
      }
    }

  },

  computed: {
    ...mapGetters(['openModalSignUpDone', 'openModalForgotten', 'modalState'])
  },

  watch: {
    openModalSignUpDone () {
      this.$store.dispatch(STATE_MODAL, {'show_modal': true})
      this.modalSignUpDone = true
    },
    openModalForgotten () {
      this.$store.dispatch(STATE_MODAL, {'show_modal': true})
      this.modalForgotten = true
    }
  }
}
</script>

<style lang="css">
.has-navbar-fixed-top {
  padding-top: 7rem !important;
}
.navbar.is-fixed-top {
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1) !important;
}
/* TOUCH MENU */
.navbar-touch .navbar-menu{
  position: fixed;
  z-index: 10;
  height: 100vh;
  max-width: 400px;
  width: 100vw;
  top:0;
  right: 0;
  background-color: #f2f5fb;
  overflow: auto;
}
.navbar-touch .navbar-layer{
  display: none;
  position: fixed;
  z-index: 9;
  width: calc(100vw);
  height: 100vh;
  top:0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
}
.navbar-touch .navbar-end {
  overflow: auto;
}
.navbar-touch .navbar-layer.is-active{
  display: block; /*Display layer on open */
}
.navbar-touch .navbar-menu .navbar-burger{
  margin-right: 1rem;
  margin-top: 0.5rem;
}
.navbar-touch .navbar-end{
  padding: 0rem 2.5rem;
}
.navbar-touch .navbar-item, .navbar-touch .navbar-link{
  padding: 1rem 0 1rem 0;
}
.navbar-touch .navbar-dropdown a{
  padding: 1rem 1rem;
}
.navbar-touch .navbar-item-connect {
  margin-top: .5rem;
  padding: 1.25rem 2rem 2rem 2.25rem;
  background-color: white;
  border-radius:6px;
}
@media screen and (max-width: 768px) {
  .navbar-touch .navbar-item-connect {
    padding: 1.25rem;
    padding-bottom: 2rem;
  }
}
/* NORMAL MENU */
.navbar {
    padding:1rem;
    background-image: linear-gradient(to top, #f0f4fa, #f1f4fa, #f1f5fb, #f2f5fb, #f2f5fb);

}
.navbar-shadow {
  box-shadow: 0px 1px 12px 0px rgba(0,0,0,0.1) !important;
}
/* NORMAL MENU DROPDOWN */
.navbar-not-touch .navbar-dropdown {
    padding: 0 ;
    border:0;
    border-radius:6px;
    box-shadow: 0px -7px 19px -1px rgba(0,0,0,0.2);
}
.navbar-not-touch .navbar-dropdown a.navbar-item {
    padding: 2rem;
    position:relative;
}
.navbar-not-touch .navbar-dropdown .navbar-item:nth-last-child(n+2):after {
    content: "";
    border-bottom: 1px solid #eeeeee;
    display: block;
    width: 90%;
    position: absolute;
    bottom: 0;
    left: 5%;
}
.navbar-not-touch .navbar-help-item:hover .navbar-link::after{
    border-color: #27c6bd !important; /*Dropdown arrow*/
}
.navbar-item.navbar-letterspacing {
    letter-spacing: 0.1ch;
    padding:0.5rem
}
</style>
