<template>
  <div class="navbar-item navbar-item-connect is-vcentered navbar-letterspacing">

    <div class="navbar-link navbar-is-not-connect-item"  @click="openModal" v-if="!isAuthenticated">
        <div class="bg-avatar"></div>
        <a class="is-uppercase">Se connecter</a>
    </div>

    <div class="navbar-item has-dropdown is-hoverable" v-else-if="isAuthenticated">
      <div class="columns is-variable is-2 is-mobile is-vcentered mon-compte">
        <div class="column is-3">
          <figure class="image" :class="displayOnTouch ? 'is-48x48' : 'is-64x64'">
              <img :src="profilPicture" alt="Je stocke mon bateau" class="is-rounded no-max-height">
          </figure>
        </div>
        <div class="column">
          <p class="navbar-link has-text-primary is-uppercase text-account" :class="displayOnTouch ? 'removePadding' : ''">
            <router-link :to="{name: 'Account'}" v-on:click.native="$emit('close-mobile-nav')"><i v-if="notif" class="fas fa-exclamation-circle" style="color:#DD4546;"></i> Mon Compte</router-link>
          </p>
          <!-- <router-link  class="navbar-item" :to="{name: 'Account'}" v-if="displayOnTouch" :class="displayOnTouch ? 'removePadding' : ''" v-on:click.native="$emit('close-mobile-nav')">Mon compte </router-link> -->
        </div>
      </div>
      <div class="navbar-dropdown" v-if="isAuthenticated">
          <router-link class="navbar-item" :to="{name: 'Profil'}" v-if="!displayOnTouch">
            Mon profil
            <span class="icon has-text-danger is-jsmb-warning" v-if="notification.profil"><i class="fas fa-exclamation-triangle"></i></span>
          </router-link>
          <router-link class="navbar-item" :to="{name: 'Annonces'}" v-if="!displayOnTouch && (userType === 'stockeur' || fullFeature)">Mes annonces</router-link>
          <router-link class="navbar-item" :to="{name: 'Reservations'}" v-if="!displayOnTouch">
            Mes réservations
            <span class="tag is-danger is-jsmb-notif" v-if="notification.booking">{{notification.booking}}</span>
          </router-link>
          <router-link class="navbar-item" :to="{name: 'Message'}" v-if="!displayOnTouch">
            Mes messages
            <span class="tag is-danger is-jsmb-notif" v-if="notification.message">{{notification.message}}</span>
          </router-link>
          <a class="navbar-item logout-item"  v-on:click="logout" :class="displayOnTouch ? 'is-pulled-right button is-danger is-small' : 'has-text-danger'">Se déconnecter</a>
      </div>
    </div>

  </div>
</template>

<script>
import { GET_NOTIFICATIONS } from '@/store/modules/database/actions/database.actions'
import { AUTH_LOGOUT } from '@/store/modules/entrance/actions/entrance.actions'
import { mapGetters } from 'vuex'

export default {
  name: 'Connect',

  data () {
    return {
      isActive: false,
      userType: localStorage.getItem('user-type'),
      fullFeature: JSON.parse(localStorage.getItem('full-feature')),
      profilPicture: require('./../../../../assets/profil-modal.png'),
      notif: false,
      notification: {
        profil: 0,
        booking: 0,
        message: 0,
        comment: 0,
      }
    }
  },
  created () {
    if (this.$store.getters.userPicture !== 'null') {
      this.profilPicture = this.$store.getters.userPicture
    }
    // Get Notifications
    this.$store.dispatch(GET_NOTIFICATIONS, {updateComment: false}).then(
      resp => {
        this.notification = resp
        if (resp.profil) {
          this.notif = true
        }
        if (resp.booking) {
          this.notif = true
        }
        if (resp.comment) {
          this.notif = true
        }
        if (resp.message) {
          this.notif = true
        }
      }
    )
  },
  methods: {
    openModal: function () {
      this.$emit('open-modal');
    },

    logout: function () {
      this.$store.dispatch(AUTH_LOGOUT)
      .then(() => this.logoutSucceed())
    },

    logoutSucceed () {
      this.$emit('close-mobile-nav')
      this.$router.push('/');
      this.$emit('logout');
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'userName', 'userPicture']),
  },
}
</script>

<style lang="css">
/* ALL STATES */
.text-account {
  justify-content: center;
}
.navbar-is-not-connect-item .bg-avatar {
    width: 38px;
    height: 38px;
    background: url('./../../../../assets/account_icon_sprites.png') 0px 0px;
    background-size: cover;
    margin-right: 0.75rem;
}
  .navbar-is-not-connect-item:hover .bg-avatar {
      background: url('./../../../../assets/account_icon_sprites.png') 38px 0;
      background-size: cover;
  }
  .navbar-is-not-connect-item a {
      color: #001c20;
  }
  .navbar-is-not-connect-item:hover a, .navbar-is-not-connect-item a:hover {
      color: #27c6bd !important;
  }
/* NOT AUTH */
.navbar-is-not-connect-item.navbar-link:not(.is-arrowless)::after{
  display: none !important; /* Hide dropdown content on touch menu */
}
.navbar-touch .navbar-is-not-connect-item{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
/* AUTH */
.navbar-item-connect .is-hoverable .navbar-link::after{
    border-color: #27c6bd !important; /*Dropdown arrow*/
}
.navbar-touch .navbar-item-connect .is-hoverable .navbar-link::after{
    border-color: #fff !important;  /*Dropdown arrow on touch menu*/
}
.navbar-touch .navbar-item-connect .logout-item{

  padding: .25rem 1rem 0 1rem;
}
.no-max-height{
  max-height: initial !important;
}
.removePadding {
  padding: 0 !important;
}
.navbar .columns:not(:last-child) {
    margin-bottom: 0rem;
}

.mon-compte {
  width: 15rem;
}
</style>
